<template>
  <div class="routeWrap">
    <div class="titleLogoWrap">
      <img class="titleLogo" :src="require('../../assets/image/route/title.png')" alt />
      <!-- <span class="title">推荐路线</!-->
    </div>

    <div class="content">
      <div class="fixedMenus" :class="barFixed?'show':''">
        <a
          class="fixedMenu qinZiLeYuan trans"
          :class="{'active':activeName==='qinZiLeYuan'}"
          data-name="qinZiLeYuan"
          @click="clickMenuItem('qinZi',$event)"
        >
          <!-- <img :src="require('../../assets/image/stay/star.png')" alt /> -->
          亲子乐园
        </a>
        <a
          class="fixedMenu tianYuan trans"
          :class="{'active':activeName==='tianYuan'}"
          data-name="tianYuan"
          @click="clickMenuItem('tianYuan',$event)"
        >
          <!-- <img :src="require('../../assets/image/stay/star.png')" alt /> -->
          田园休闲
        </a>
        <a
          class="fixedMenu kangYang trans"
          :class="{'active':activeName==='kangYang'}"
          data-name="kangYang"
          @click="clickMenuItem('kangYang',$event)"
        >
          <!-- <img :src="require('../../assets/image/stay/star.png')" alt /> -->
          康养度假
        </a>
      </div>

      <div id="i_menusWrap" class="menusWrap">
        <!-- <div class="menuWrap"> -->
        <a
          class="menuWrap qinZiLeYuan maskWrap size toShow"
          :class="{'active':activeName==='qinZiLeYuan'}"
          data-name="qinZiLeYuan"
          @click="clickMenuItem('qinZi',$event)"
        >
          <img :src="require('../../assets/image/route/tab_1.png')" alt />
          <span>亲子乐园</span>
        </a>
        <!-- </div> -->
        <!-- <div class="menuWrap"> -->
        <a
          class="menuWrap tianYuan maskWrap size toShow"
          :class="{'active':activeName==='tianYuan'}"
          data-name="tianYuan"
          @click="clickMenuItem('tianYuan',$event)"
        >
          <img :src="require('../../assets/image/route/tab_2.png')" alt />
          <span>田园休闲</span>
        </a>
        <!-- </div> -->
        <!-- <div class="menuWrap"> -->
        <a
          class="menuWrap kangYang maskWrap size toShow"
          :class="{'active':activeName==='kangYang'}"
          data-name="kangYang"
          @click="clickMenuItem('kangYang',$event)"
        >
          <img :src="require('../../assets/image/route/tab_3.png')" alt />
          <span>康养度假</span>
        </a>
        <!-- </div> -->
      </div>
      <!-- 亲子乐园 -->
      <div id="qinZi" class="qinZiLeYuan bigGroup">
        <img class="groupTitle" :src="require('../../assets/image/route/g1_title.png')" alt />
        <div class="group">
          <img class="groupTitle" :src="require('../../assets/image/route/g1_1.png')" alt />
          <div class="starsGroup">
            <m-star-bar title="欢乐指数：" class="starBar"></m-star-bar>
            <m-star-bar title="休闲指数：" class="starBar"></m-star-bar>
            <m-star-bar title="尖叫指数：" class="starBar"></m-star-bar>
          </div>
          <!-- <div class="btnWrap">
            <img class="btnPic ilarge" :src="require('../../assets/image/route/btn.png')" alt />
          </div>-->
        </div>
        <div class="group">
          <img class="groupTitle" :src="require('../../assets/image/route/g1_2.png')" alt />
          <div class="starsGroup">
            <m-star-bar title="欢乐指数：" class="starBar"></m-star-bar>
            <m-star-bar title="休闲指数：" class="starBar"></m-star-bar>
            <m-star-bar title="尖叫指数：" class="starBar"></m-star-bar>
          </div>
          <!-- <div class="btnWrap">
            <img class="btnPic ilarge" :src="require('../../assets/image/route/btn.png')" alt />
          </div>-->
        </div>
      </div>

      <!-- 田园休闲 -->
      <div id="tianYuan" class="tianYuanXiuXian bigGroup">
        <div class="group">
          <img class="groupTitle" :src="require('../../assets/image/route/g3_1.png')" alt />
          <div class="starsGroup">
            <m-star-bar title="欢乐指数：" class="starBar"></m-star-bar>
            <m-star-bar title="休闲指数：" class="starBar"></m-star-bar>
            <m-star-bar title="尖叫指数：" class="starBar"></m-star-bar>
          </div>
          <!-- <div class="btnWrap">
            <img class="btnPic ilarge" :src="require('../../assets/image/route/btn.png')" alt />
          </div>-->
        </div>
      </div>

      <!-- 康养度假 -->
      <div id="kangYang" class="kangYangDuJia bigGroup">
        <div class="group">
          <img class="groupTitle" :src="require('../../assets/image/route/g2_1.png')" alt />
          <div class="starsGroup">
            <m-star-bar title="欢乐指数：" class="starBar"></m-star-bar>
            <m-star-bar title="休闲指数：" class="starBar"></m-star-bar>
            <m-star-bar title="尖叫指数：" class="starBar"></m-star-bar>
          </div>

          <!-- <div class="btnWrap">
            <img class="btnPic ilarge" :src="require('../../assets/image/route/btn.png')" alt />
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import TitleGapBar from "@/components/TitleGapBar";
import StarBar from "@/components/starBar";
import mx_utils from "@/mixins/mx_utils";
export default {
  components: {
    [TitleGapBar.name]: TitleGapBar,
    [StarBar.name]: StarBar
  },
  data() {
    return {
      barFixed: false,
      activeName: ""
    };
  },
  mixins: [mx_utils],
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!document.querySelector("#i_menusWrap")) return;
      let distOffsetTop = document.querySelector("#i_menusWrap").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }
      if (scrollTop > distOffsetTop - 120) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }

      //滚动到亲子乐园的范围1126
      if (scrollTop > 1126) {
        this.activeName = "qinZiLeYuan";
      }
      // 滚动到田园休闲的范围2638 tianYuan
      if (scrollTop > 2638) {
        this.activeName = "tianYuan";
      }

      //滚动到康养度假的范围3541 kangYang
      if (scrollTop > 3541) {
        this.activeName = "kangYang";
      }
      // console.log("window scrollTop:", scrollTop);
      // console.log("目标 distOffsetTop:", distOffsetTop);
      // console.log("fixed标识：", this.barFixed);
    },
    clickMenuItem(anchorId, e) {
      if (!e.currentTarget.getAttributeNode("data-name")) return;
      this.activeName = e.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
      this.goNavArea(anchorId);
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.routeWrap {
  width: 1400px;
  text-align: center;
  border: 1px solid transparent;
  .titleLogoWrap {
    position: relative;
    > .titleLogo {
      margin-top: 28px;
    }
    .title {
      position: absolute;
      left: 130px;
      top: 34px;
      font-size: 31px;
      line-height: 82px;
      margin-left: 100px;
    }
  }
  .groupTitle {
    display: block;
    margin: 0 auto;
  }

  > .content {
    border: 7px solid $main-bgOrange;
    margin: 0 60px 30px 60px;

    .fixedMenus {
      position: fixed;
      top: 120px;
      width: 1280px;
      z-index: 999;
      display: none;
      margin: 0 auto;

      &.show {
        display: block;
      }
      > .fixedMenu {
        display: inline-block;
        width: 382px;
        height: 80px;
        line-height: 80px;
        background: $font-grayDarkD;
        font-size: 28px;
        font-weight: bold;
        color: $main-white;
        text-align: center;
        border-right: 1px solid $font-grayDark;
        cursor: pointer;

        > img {
          margin-right: 10px;
        }
        &.qinZiLeYuan {
          border-bottom: 10px solid $main-blue;
          // border-bottom-left-radius: 10px;
          &:hover,
          &.active {
            background: $main-blue;
          }
        }
        &.tianYuan {
          border-bottom: 10px solid $main-greenGrass;
          &:hover,
          &.active {
            background: $main-greenGrass;
          }
        }
        &.kangYang {
          border-bottom: 10px solid $main-purple;
          // border-bottom-right-radius: 10px;
          &:hover,
          &.active {
            background: $main-purple;
          }
        }
      }
      > .fixedMenu:nth-of-type(3) {
        border-right: 0;
        // margin-left:5px;
      }
    }

    > .menusWrap {
      width: 1090px;
      margin: 33px auto 0 auto;
      display: flex;
      justify-content: space-between;
      .menuWrap {
        position: relative;
        display: inline-block;
        width: 253px;
        height: 68px;
        line-height: 68px;
        background: $main-blue;
        text-align: center;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        cursor: pointer;
        &.tianYuan {
          background: $main-greenGrass;
        }
        &.kangYang {
          > img {
            top: -21px;
            left: -41px;
          }
          background: $main-purple;
        }

        img {
          position: absolute;
          top: -20px;
          left: -26px;
          cursor: pointer;
        }
        span {
          font-size: 24px;
          color: $main-white;
        }
      }
    }
    .menuWrap.qinZiLeYuan {
      margin-left: 10px;
    }

    .bigGroup {
      padding-top: 54px;
      .group {
        position: relative;

        .starsGroup {
          position: absolute;
          left: 104px;
          bottom: 123px;

          .starBar {
            margin-bottom: 20px;
          }
        }
        .btnPic {
          position: absolute;
          width: 138px;
          height: 40px;
          left: 104px;
          bottom: 31px;
          cursor: pointer;
        }
      }
    }
    .bigGroup.kangYangDuJia.bigGroup {
      // border:1px solid transparent;
      margin-bottom: 30px;
    }
  }
}
img {
  cursor: default;
}
</style>

